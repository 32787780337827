var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "linshizhiliao" },
    _vm._l(_vm.result, function (item, index) {
      return _c(
        "van-swipe-cell",
        {
          key: index,
          staticClass: "agent-swiper-cell",
          attrs: { "right-width": 192 },
        },
        [
          _c(
            "van-cell",
            [[_c("div", [_vm._v(" " + _vm._s(item.content) + " ")])]],
            2
          ),
          _c(
            "span",
            {
              staticClass: "delete",
              attrs: { slot: "right" },
              on: {
                click: function ($event) {
                  return _vm.deleteItem(item)
                },
              },
              slot: "right",
            },
            [_vm._v("删除")]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }