<template>
  <div class="linshizhiliao">
    <van-swipe-cell
      :right-width="192"
      v-for="(item, index) of result"
      :key="index"
      class="agent-swiper-cell"
    >
      <!-- :left-width="192" -->
      <!-- <span
        slot="left"
        @click="deleteCell"
        class="delete"
      >采用</span> -->
      <van-cell>
        <template>
          <div>
            {{ item.content }}
          </div>
        </template>
      </van-cell>
      <span class="delete" slot="right" @click="deleteItem(item)">删除</span>
    </van-swipe-cell>
  </div>
</template>

<script>
import axios from 'axios'
import { caseBase } from '~api-config'
export default {
  data() {
    return {
      result: []
    }
  },
  methods: {
    deleteCell() {
      console.log('采用')
    },
    deleteItem(item) {
      this.$axios
        .post(`${caseBase}/farbun/temp/delete/${item.id}`)
        .then(async response => {
          const userId = this.$store.state.user.userInfo.id
          try {
            const response = await axios.get(
              caseBase + '/farbun/temp/list?userId=' + userId
            )
            const { temps } = response.data.data
            if (temps.length === 0) {
              this.$toast.fail({
                duration: 1000,
                forbidClick: true,
                loadingType: 'spinner',
                message: '暂无数据'
              })
            } else {
              this.result = temps.filter(item => item.content)
            }
          } catch (err) {
            this.$toast.fail('服务器繁忙')
          }
        })
      console.log(item)
    }
  },
  async created() {
    const userId = this.$store.state.user.userInfo.id
    try {
      const response = await axios.get(
        caseBase + '/farbun/temp/list?userId=' + userId
      )
      const { temps } = response.data.data
      if (temps.length === 0) {
        this.$toast.fail({
          duration: 1000,
          forbidClick: true,
          loadingType: 'spinner',
          message: '暂无数据'
        })
      } else {
        this.result = temps.filter(item => item.content)
      }
    } catch (err) {
      this.$toast.fail('服务器繁忙')
    }
  },
  mounted() {
    setTimeout(() => {
      document
        .querySelectorAll('.linshizhiliao .van-swipe-cell__right')
        .forEach(item => {
          item.style.width = '192px'
        })
      document
        .querySelectorAll('.linshizhiliao .van-swipe-cell__left')
        .forEach(item => {
          item.style.width = '192px'
        })
    }, 300)
  }
}
</script>
<style lang="stylus">
.linshizhiliao {
  padding: 15px;
  height: calc(100vh - 176px + 44px + 44px);
  overflow: hidden;
  overflow-y: auto;

  .agent-swiper-cell {
    .van-swipe-cell__wrapper {
      background: #F2F8FF;
      border-radius: 5px;

      .van-cell {
        padding: 5px;
        background: transparent;
        margin-bottom: 5px;
        color: #666;
      }

      .van-swipe-cell__right, .van-swipe-cell__left {
        color: #fff;
        font-size: 15px;
        height: 100%;
        width: 192px;
        display: inline-block;
        text-align: center;
        background-color: #f44;
        box-sizing: border-box;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .van-swipe-cell__left {
        background-color: #569AF9;
      }
    }
  }

  & .delete {
    display: inline-flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
</style>
